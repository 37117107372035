import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout/Layout";
import PageBackground from "../components/Layout/PageBackground";

import useFeaturedCoursesData from "../hooks/featured-courses-data";
import HomePageHeroSectionTwo from "../components/HomePageHero/HomePageHeroSectionTwo";
import BubbleSlider from "../components/BubbleSlider/BubbleSlider";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import Community from "../components/CommunityHomePageSection/Community";
import TestimonialWithImage from "../components/TestimonialWithImage/TestimonialWithImage";
import JoinTheFoundry from "../components/Foundry/child-components/JoinTheFoundry";
import CenteredRichText from "../components/CenteredRichText/CenteredRichText";
import { theme } from "../utils/global-styles/theme";

const IndexPage = ({ location: { pathname }, data }) => {
  const homepageBenefits = data?.page.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "homepageBenefits"
  );

  const queryData = data?.page?.nodes[0],
    slides = data?.page?.nodes[0].contentModules.filter(
      module => module.pageSectionTitle === "factsCarousel"
    )[0].carouselItems;

  const featuredCourses = useFeaturedCoursesData(),
    { heading, courses } = featuredCourses[4],
    { linkText, url } = featuredCourses[5],
    [isMobileView, setMobileView] = useState(false),
    { marketingModal } = queryData;

  const handleResize = () => {
    window.innerWidth < 801 ? setMobileView(true) : setMobileView(false);
  };

  useEffect(() => {
    // If executed server-side (no window object) it will return false(exit) out of the useEffect() hook
    if (typeof window !== "object") {
      return false;
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const PageWrapper = styled.div`
    width: 100%;
    display: block;
  `;
  const becomeAFellowData = data?.page?.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "becomeAFellow"
  )[0];
  const accelerateImpactData = data?.page?.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "accelerateYourImpact"
  )[0];
  const homepageIntroData = data?.page?.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "homepageIntro"
  )[0];

  const testimonialData = data?.page?.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "testimonials"
  )[0];

  const twinCardSectionData = data?.page?.nodes[0].contentModules.filter(
    module => module.pageSectionTitle === "fullWidthTwinCards"
  )[0];

  const renderSection = pageSectionTitle => {
    switch (pageSectionTitle) {
      case "homepageIntro":
        return (
          <PageBackground key={"homepageIntro"}>
            <CenteredRichText text={homepageIntroData?.richText} />
          </PageBackground>
        );

      case "factsCarousel":
        return (
          <PageBackground className="dark-blue-bg" key={"factsCarousel"}>
            <BubbleSlider slides={slides} />
          </PageBackground>
        );

      case "becomeAFellow":
        return (
          <PageBackground>
            <Community data={becomeAFellowData} key={"becomeAFellow"} />
          </PageBackground>
        );

      case "fullWidthTwinCards":
        return (
          <JoinTheFoundry
            cards={twinCardSectionData.cards}
            heading={twinCardSectionData.header}
          />
        );

      case "testimonials":
        return (
          <TestimonialWithImage
            isHomePage={true}
            testimonials={testimonialData.testimonials}
          />
        );

      case "accelerateYourImpact":
        return (
          <PageBackground>
            <Community data={accelerateImpactData} key={"accelerateImpact"} />
          </PageBackground>
        );
      default:
        return null;
    }
  };

  const IndexPageWrapper = styled.div`
    width: 100%;
    display: block;
    transform: translateY(3%);
    transition: transform 500ms ease-in-out;

    @media only screen and (min-width: 599px) {
      transform: translateY(9%);
    }

    @media (min-width: 700px) and (max-width: ${theme.breakpoints
        .iPadPro}) {
      transform: translateY(5%);
    }
  `;

  const Body = styled.div`
    margin-bottom: 14vw;
  `;

  const containerRef = useRef(null);
  const animatedPartOfLogoRef = useRef(null);
  const bodyPartRef = useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 20;
      if (scrollY > threshold) {
        // shrinking
        animatedPartOfLogoRef.current.style.transform =
          window.innerWidth < 599
            ? "translateY(-57%) scale(1.2)"
            : "translateY(-59%) scale(1.2)";
        bodyPartRef.current.style.transform =
          window.innerWidth < 599 ? "translateY(0%)" : "translateY(5%)";
      } else {
        // expanding
        animatedPartOfLogoRef.current.style.transform =
          window.innerWidth < 599
            ? "translateY(-175%) scale(6.2)"
            : window.innerWidth > 700 && window.innerWidth < 1025
            ? "translateY(-264%) scale(12.2)"
            : "translateY(-500%) scale(22.2)";
        bodyPartRef.current.style.transform =
          window.innerWidth < 599
            ? "translateY(3%)"
            : window.innerWidth > 700 && window.innerWidth < 1025
            ? "translateY(5%)"
            : "translateY(9%)";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout
      currentPath={pathname}
      enforceWidth={false}
      marketingModal={marketingModal}
      containerRef={containerRef}
      animatedPartOfLogoRef={animatedPartOfLogoRef}
    >
      <IndexPageWrapper ref={bodyPartRef}>
        <HomePageHeroSectionTwo />
        <Body>
          {data?.page?.nodes[0].contentModules?.map(module => {
            return renderSection(module?.pageSectionTitle);
          })}
        </Body>
      </IndexPageWrapper>
    </Layout>
  );
};
export const query = graphql`
  query {
    page: allContentfulPageLayout(
      filter: { queryIdentifier: { eq: "Homepage-tonys-clone" } }
    ) {
      nodes {
        contentModules {
          ... on ContentfulBenefitsCard {
            pageSectionTitle
            benefit1
            description1
            cta1 {
              url
              linkText
            }
            benefit2
            description2
            cta2 {
              url
              linkText
            }
            benefit3
            description3
            cta3 {
              url
              linkText
            }
          }
          ... on ContentfulTestimonialsSection {
            pageSectionTitle
            testimonials {
              authorName
              authorTitle
              image {
                fluid {
                  srcSet
                }
              }
              testimonialQuote {
                testimonialQuote
              }
            }
          }
          ... on ContentfulFullWidthTwinCardsSection {
            pageSectionTitle
            header
            cards {
              title
              description {
                description
              }
              link {
                linkText
                url
              }
            }
          }
          ... on ContentfulRichTextModule {
            pageSectionTitle
            richText {
              json
            }
          }
          ... on ContentfulUpcomingCoursesFeaturedBlogs {
            pageSectionTitle
            heading
            courses {
              ... on ContentfulBlogDetailPage {
                theme {
                  theme
                  themeNumber
                }
                title
                urlCtaText
                url
                blogImage {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                  description
                }
              }
            }
            linkText
            url
          }
          ... on ContentfulTitleDescription {
            pageSectionTitle
            title
            description {
              description
            }
          }
          ... on ContentfulCarouselContent {
            pageSectionTitle
            heading
            carouselDescription: description
            carouselItems {
              ... on ContentfulTitleDescription {
                name
                factsHighlight
                description {
                  description
                }
                image {
                  fluid {
                    srcSet
                  }
                  description
                }
              }
              ... on ContentfulImage {
                image {
                  fluid {
                    srcSet
                    src
                    sizes
                    base64
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on ContentfulFeaturedSection {
            pageSectionTitle
            heading
            featuredSectionDescription: description
            cta {
              linkText
              url
            }
            image {
              fluid {
                srcSet
              }
              description
            }
          }
        }
        marketingModal {
          ... on ContentfulMarketingModal {
            id
            cta {
              linkText
              url
            }
            description {
              description
            }
            title
            showAfterPercent
            hubspotListId
            marketingModalImage {
              image {
                fluid {
                  aspectRatio
                  srcSet
                }
              }
            }
            formFields
            successTitle
            successDescription
            additionalText {
              json
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
