import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { theme } from "../../utils/global-styles/theme";

const CenteredRichText = ({ text }) => {
  const Paragraph = styled.p`
    margin-bottom: 1em;
  `;
  const Container = styled.section`
    margin: 40px 342px;
    @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
        margin: 40px 10%;
      }
  `;
  const richTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return children && children[0] !== "" ? (
          <Paragraph>{children}</Paragraph>
        ) : null;
      },
    },
  };
  return <Container>{documentToReactComponents(text.json, richTextOptions)}</Container>;
};

CenteredRichText.propTypes = {};

export default CenteredRichText;
