import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import { isLoggedIn, getUserDetails } from "../../utils/services/methods";
import { _post } from "../../utils/services/api.service";
import { useForm } from "react-hook-form";
import RightGoToArrow from "../../components/CourseCard/Icons/RightGoToArrow";

const HomePageHeroNewsLetterForm = props => {
  const NewsLetterFormWrapper = styled.form`
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-bottom: 1.375rem;

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin: 0 auto;
      }
    `,
    NewsLetterTitle = styled.div`
      color: ${theme.colors.white};
      font-size: 18px;
      font-family: ${theme.fontFaces.paragraphs};
      width: 80%;
      display: block;
      margin: 0 auto;
      margin-top: 1.375rem;
      margin-bottom: 1.375rem;
      font-weight: normal;

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        font-size: 17px;
        width: 40%;
        text-align: left;
        margin-left: 80px;
        margin-right: 10%;
      }
    `,
    NewsLetterContainer = styled.div`
      display: flex;
      flex-direction: column;
      color: ${theme.colors.white};
      text-align: center;
      position: relative;
      width: 100%;
    `,
    MessageWrapper = styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
      font-family: ${theme.fontFaces.paragraphs};
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        font-size: 2.063rem;
      }
    `,
    StyledEmailInput = styled("input")`
      padding: 0.563rem 1.5rem 0.536rem 0rem;
      font-size: 1rem;
      font-family: ${theme.fontFaces.paragraphs};
      margin-right: 10px;

      &:focus {
        outline: none;
      }

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        width: 300px;
      }
    `,
    NewsletterSubmitButton = styled.button`
      height: 1.75rem;
      color: ${theme.colors.white};
      border: none;
      font-family: ${theme.fontFaces.paragraphs};
      cursor: pointer;
      width: 38px;
      height: 38px;
    `,
    MobileInputContainer = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
    `,
    Error = styled.div`
      width: 100%;
      margin-top: 0.7rem;
      font-family: ${theme.fontFaces.paragraphs};

      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) {
        position: absolute;
        top: 98px;
        padding-left: 14%;
        margin-top: 0.2rem;
      }

      @media (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) and (max-width: ${theme.breakpoints.desktopUp}) {
        left: 0%;
        padding-left: 9%;
      }
    `,
    EmailAndErrorWrapper = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `;

  const { newsletterTitle, emailPlaceholder } = props,
    userData = getUserDetails(),
    validateEmail = emailInput => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(emailInput).toLowerCase());
    },
    onSubmit = async data => {
      setNewsletterMessage("Thanks for signing up!");
      const { email } = data;
      const request = {
        email: email,
      };
      if (isLoggedIn()) {
        request.first_name = userData.first_name;
        request.last_name = userData.last_name;
      }
      await _post({
        isPublic: true,
        args: request,
        endpoint: "newsletters",
      });
    },
    [newsletterMessage, setNewsletterMessage] = useState(null),
    {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        email: "",
      },
    });

  return (
    <NewsLetterContainer className="dark-blue-bg">
      {newsletterMessage ? (
        <MessageWrapper>{newsletterMessage}</MessageWrapper>
      ) : (
        <NewsLetterFormWrapper onSubmit={handleSubmit(onSubmit)}>
          <NewsLetterTitle>{newsletterTitle}</NewsLetterTitle>
          <MobileInputContainer>
            <StyledEmailInput
              className="dark-blue-bg email-input"
              type="email"
              name="email"
              {...register("email", {
                required: true,
                validate: {
                  isValid: v =>
                    validateEmail(v) || "Please enter a valid email",
                },
              })}
              type="email"
              placeholder={emailPlaceholder}
            />
            <NewsletterSubmitButton
              label="Submit newsletter form"
              type="submit"
              className="dark-blue-bg"
              role="button"
              aria-label="Submit Newsletter form"
            >
              <RightGoToArrow />
            </NewsletterSubmitButton>
          </MobileInputContainer>

          {errors.email && (
            <Error className={"secondary-bg"}> {errors.email.message} </Error>
          )}
        </NewsLetterFormWrapper>
      )}
    </NewsLetterContainer>
  );
};

export default HomePageHeroNewsLetterForm;
