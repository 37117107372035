import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

import { theme } from "../../../utils/global-styles/theme";

import CloseIcon from "../assets/CloseIcon";

const Modal = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.66);
    z-index: 8;
  `,
  ModalContent = styled.div`
    position: fixed;
    height: 80%;
    width: 80%;
    background-color: transparent;
    margin: 4em 0;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      width: 90%;
      height: 50%;
    }
  `,
  CloseModalContainer = styled.div`
    display: flex;
    align-items: center;
    float: right;
    margin-bottom: 0.5em;
    cursor: pointer;
    p {
      margin-right: 0.5em;
      color: ${theme.colors.white};
    }
  `,
  VideoOverlay = ({ show, closeVideoOverlay, videoUrl, title }) => {
    return (
      <>
        {show && (
          <>
           <Global
           styles={css`
            body {
              overflow-y: hidden;
            }
           `}
         />
          <Modal>
            <ModalContent>
              <CloseModalContainer onClick={() => closeVideoOverlay()}>
                <p>Close</p>
                <CloseIcon />
              </CloseModalContainer>
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                frameborder="0"
                title={title}
                allowfullscreen
              ></iframe>
            </ModalContent>
          </Modal>
          </>
        )}
      </>
    );
  };

VideoOverlay.propTypes = {
  show: PropTypes.bool,
  closeVideoOverlay: PropTypes.func,
  videoUrl: PropTypes.string,
  title: PropTypes.string,
};

export default VideoOverlay;
