import React, { useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import { Link } from "gatsby";

const MainTitle = styled.h2`
  font-size: 30px;
  transform: translateY(70%);
  display: none;
  position: absolute;

  @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
    display: unset;
    font-size: 30px;
    font-weight: bold;
    font-family: ${theme.fontFaces.headings};
    color: black;
    line-height: 35px;
    opacity: 1;
  }

  @media (min-width: ${theme.breakpoints.iPadPro}) and (max-width: ${theme
    .breakpoints.desktopUp}) {
      display: none;
    }
`;

const HoverCTA = styled(Link)`
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 17px;
  line-height: 22px;
  text-decoration: underline;
  font-weight: normal;
`;

const HoverContentDesktop = styled.div`
  display: flex;
  opacity: 0;
  height: 0px;
  flex-direction: column;

  @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
    display: none;
  }
`;

const HoverContentMobile = styled.div`
  display: flex;
  opacity: 1;
  flex-direction: column;
  padding-top: 40px;

  @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
    display: none;
  }
`;

const HoverTitle = styled.h2`
  font-size: 19px;
  line-height: 25px;
  color: black;
  margin-bottom: 9px;
  transform: scale(1.2);

  @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
    font-size: 20px;
  }
`;

const HoverTitleMobile = styled.h2`
  font-size: 25px;
  line-height: 30px;
  color: black;
  margin-bottom: 12px;

  @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
    display: none;
  }
`;

const HoverDescription = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
    display: none;
  }

  width: 100%;
  left: 0;
  color: ${theme.colors.main};
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 12px;
  font-weight: normal;
  z-index: 3;
  transform: translateY(-30%);
`;

const HoverDescriptionMobile = styled.div`
  width: 100%;
  left: 0;
  color: ${theme.colors.main};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  font-weight: normal;
  z-index: 3;

  @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
    display: none;
  }
`;

const LinkTextContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;

  &:hover ${MainTitle} {
    opacity: 0;
  }

  &:hover ${HoverContentDesktop} {
    position: absolute;
    margin-top: 12px;
    opacity: 1;
    font-size: 20px;
    transition: opacity 0.4s ease;
  }

  &:hover ${HoverDescription} {
    transform: translateY(10%);
    transition: transform 0.3s ease;
  }

  &:hover ${HoverTitle} {
    transform: scale(1);
    transform-origin: top left;
    transition: transform 0.3s ease;
  }

  @media only screen and (min-width: ${theme.breakpoints.desktopUp}) {
    height: 170px;
    margin: unset;
    padding-bottom: 0px;
    width: 80%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
    flex-direction: column;
  }
`;

const RightArrowWithoutCircle = styled.div`
  margin-left: 5px;
`;

const redirectTo = url => {
  window.location.href = url;
};

const showLinks = linkList => {
  return linkList?.map((link, i) => {
    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + " ...";
      }
      return text;
    };

    return (
      <LinkTextContainer className="dark-blue-bottom-border" key={i}>
        <MainTitle>{link.linkTitle}</MainTitle>
        <HoverContentDesktop>
          <HoverTitle>{link.linkTitle}</HoverTitle>
          <HoverDescription className="header-links-font">
            {truncateText(link.linkDescription, 120)}
          </HoverDescription>
          <HoverCTA className="header-links-font" to={link?.linkDetails?.url}>
            {link?.linkDetails?.linkText}{" "}
            <RightArrowWithoutCircle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
              >
                <path
                  id="Path_971"
                  data-name="Path 971"
                  d="M9.625,6.75H.5V5.25H9.625L5.438,1.063,6.5,0l6,6-6,6L5.438,10.938Z"
                  transform="translate(-0.5)"
                />
              </svg>
            </RightArrowWithoutCircle>
          </HoverCTA>
        </HoverContentDesktop>
        <HoverContentMobile>
          <HoverTitleMobile>{link.linkTitle}</HoverTitleMobile>
          <HoverDescriptionMobile className="header-links-font">
            {truncateText(link.linkDescription, 120)}
          </HoverDescriptionMobile>
          <HoverCTA className="header-links-font" to={link?.linkDetails?.url}>
            {link?.linkDetails?.linkText}{" "}
            <RightArrowWithoutCircle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
              >
                <path
                  id="Path_971"
                  data-name="Path 971"
                  d="M9.625,6.75H.5V5.25H9.625L5.438,1.063,6.5,0l6,6-6,6L5.438,10.938Z"
                  transform="translate(-0.5)"
                />
              </svg>
            </RightArrowWithoutCircle>
          </HoverCTA>
        </HoverContentMobile>
      </LinkTextContainer>
    );
  });
};

const HomepageHeaderLinks = props => {
  const { linkList } = props;

  return <Wrapper>{showLinks(linkList)}</Wrapper>;
};

export default HomepageHeaderLinks;
