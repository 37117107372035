import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";

const HomepageBenefits = ({ benefits }) => {
  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: ${theme.breakpoints.tabletLandscapeUp}) {
      flex-direction: row;
    }
  `;
  const Benefit = styled.h2`
    font-size: 25px;
    font-weight: bold;
    color: black;
    padding: 50px 80px;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
  `;

  const showBenefits = () => {
    return benefits.map(benefit => {
      return <Benefit>{benefit}</Benefit>;
    });
  };
  return <Wrapper className="primary-bg">{showBenefits()}</Wrapper>;
};

export default HomepageBenefits;
