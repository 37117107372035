import { graphql, useStaticQuery } from "gatsby";

const useHomepageHeroTwo = () => {
  const data = useStaticQuery(graphql`
    query {
      homepage: allContentfulHomepageHero(
        filter: { queryIdentifier: { eq: "Homepage Hero Three" } }
      ) {
        nodes {
          emailPlaceholder
          linkListTitle
          newsletterButtonText
          newsletterTitle
          title
          subtitle
          leftSideCta {
            url
            linkText
          }
          heroImage {
            image {
              fluid {
                aspectRatio
                srcSet
              }
            }
          }
          linkList {
            ... on ContentfulHomepageHeroLink {
              id
              linkTitle
              linkDescription
              linkDetails {
                linkText
                url
              }
            }
          }
          linkListCta {
            url
            linkText
          }
          heroBenefits
        }
      }
    }
  `);

  return data.homepage.nodes[0];
};

export default useHomepageHeroTwo;
