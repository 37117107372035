import { graphql, useStaticQuery } from "gatsby";

const useFeaturedCoursesData = () => {
  const data = useStaticQuery(graphql`
    query {
      homepage: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Homepage" } }
      ) {
        nodes {
          contentModules {
            ... on ContentfulUpcomingCoursesFeaturedBlogs {
              heading
              courses {
                ... on ContentfulCourseDetailPage {
                  courseImage {
                    fluid {
                      srcSet
                      aspectRatio
                    }
                  }
                  title
                  courseType
                  courseCost
                  courseStartDate
                  courseId
                  slug
                  courseDuration
                  theme: theme20 {
                    theme
                  }
                  courseAvailability
                }
              }
            }
            ... on ContentfulLink {
              linkText
              url
            }
          }
        }
      }
    }
  `);
  return data.homepage.nodes[0].contentModules;
};

export default useFeaturedCoursesData;
