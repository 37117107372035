import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";
import Img from "gatsby-image";
import VideoIcon from "../BecomeAFellow/assets/VideoIcon";
import VideoOverlay from "../BecomeAFellow/child-components/VideoOverlay";

const GridArea = styled.div`
  display: grid;
  grid-template-columns: 9.5% 46% auto;
  grid-template-rows: 116px auto auto;

  @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
    grid-template-columns: 10% auto 10%;
    grid-template-rows: auto auto auto;
  }
`;

const TextArea = styled.div`
  grid-area: 2 / 2 / 2 / 2;
  @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
    grid-area: 1 / 2 / 1 / 2;
  }
`;

const LinkArea = styled.div`
  grid-area: 3 / 2 / 3 / 2;
  margin-top: 33px;

  @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
    margin: 23px 0;
    grid-area: 2 / 2 / 2 / 2;
  }
`;
const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${theme.colors.black};
  font-size: 25px;
`;

const ImageContainer = styled.div`
  top: -150px;
`;

const SectionHeader = styled.h2`
  margin-left: 80px;
  margin-top: 57px;
  @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }
`;

const ImageArea = styled.div`
  grid-area: 3 / 3 / span 1 / span 1;
  position: relative;
  min-height: 240px;
  @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
    grid-area: 3 / 2 / 3 / 2;
    position: static;
  }
`;

const ImageContent = styled(Img)`
    width: 482px;
    height: 251px;
    top: -50px;
    @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
      width: 100%;
      top: 0;
    }
  `,
  WatchVideoLinkContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1px;
    padding-top: 1em;
    padding-left: 1em;
    cursor: pointer;
    top: -50px;
    position: relative;
    @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
      position: static;
      margin-bottom: 10%;
      padding-left: 2em;
    }
  `,
  WatchVideoLink = styled.div`
    border-bottom: 1px solid;
    margin-left: 0.5em;
    font-family: ${theme.fontFaces.headers};
    color: ${theme.colors.black};
    font-size: 16px;
  `,
  Description = styled.h3`
    font-size: 33px;
    line-height: 40px;
    font-family: Gellix-Medium, serif;
  `;

const Community = ({ locale, data }) => {
  const [showOrRemoveVideoOverlay, setShowOrRemoveVideoOverlay] = useState(
      false
    ),
    {
      featuredSectionDescription,
      heading,
      cta,
      videoStillImage,
      watchVideoCta,
    } = data,
    closeVideoOverlay = () => {
      setShowOrRemoveVideoOverlay(false);
    };

  return (
    <>
      <SectionHeader>{heading}</SectionHeader>
      {watchVideoCta?.url && (
        <VideoOverlay
          show={showOrRemoveVideoOverlay}
          closeVideoOverlay={closeVideoOverlay}
          videoUrl={watchVideoCta.url}
          title={heading}
        />
      )}
      <GridArea>
        <TextArea>
          <Description>{featuredSectionDescription}</Description>
        </TextArea>
        <LinkArea>
          <StyledLink className="underline-effect" to={`${cta.url}/`}>
            {cta.linkText}
          </StyledLink>
        </LinkArea>
        <ImageArea>
          {videoStillImage?.image?.fluid && (
            <ImageContainer>
              <ImageContent fluid={videoStillImage.image.fluid} />
              <WatchVideoLinkContainer
                onClick={() => setShowOrRemoveVideoOverlay(true)}
              >
                <VideoIcon className="svg-path-black-fill" />
                <WatchVideoLink
                  tabIndex="0"
                  className="primary underline-effect"
                >
                  {watchVideoCta.linkText}
                </WatchVideoLink>
              </WatchVideoLinkContainer>
            </ImageContainer>
          )}
        </ImageArea>
      </GridArea>
    </>
  );
};

Community.propTypes = {};

export default Community;
